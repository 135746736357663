import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Promise from 'promise';
import { validateForm } from '../../assets/js/custom-validation';
import './contact-form.scss';

const API_URL = 'http://wiztech.com.pk/api';

//const API_URL = process.env.GATSBY_API_URL;


/* eslint-disable-next-line */
class ContactForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
        // file: '',
        mailSent: false,
        error: null,
        isValidated: false,
    };
}

handleFormSubmit(event) {
    event.preventDefault();

    //Reset State
    this.setState({ error: null, mailSent: false });

    const formEl = this.formEl;

    //If form validate
    if (validateForm(formEl)) {
        let data = this.state;

        const formData = new FormData();
        // for(const i in data){
        //   if(i == 'file'){
        //     if(data[i].length > 0){
        //       formData.append(i, data[i][0])
        //     }
        //   } else {
        //     formData.append(i, data[i])
        //   }

        // }

        return new Promise((resolve, reject) => {


            axios({
                method: 'post',
                url: `${API_URL}/contact.php`,
                headers: { 'content-type': 'application/json', 'Authorization': 'Wiztech' },
                data: formData,
            })
                .then(result => {
                    if (result.data.message) {
                        this.setState({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phoneNumber: '',
                            subject: '',
                            message: '',
                            // file: '',
                            mailSent: true,
                            isValidated: false,
                        })
                    } else {
                        this.setState({ error: result.data.error })
                    }
                })
                .catch(error => this.setState({ error: error.message }));
        });
    } else {

        this.setState({ isValidated: true });
        return false;
    }

}
// ValidateFileExt(fileName){
//   const abcfile =  fileName.file[0].name;
//   const fileExt =abcfile.split('.').pop();
//   const allowedExt = ['pdf','doc','docx'];

//   if(allowedExt.indexOf(fileExt) > -1){
//     const fileLabel = document.getElementById("fileLabel") as HTMLElement;
//     fileLabel.innerText=null;
//     fileLabel.append(abcfile);
//   } else {
//     alert('Invalid File');
//     //reset field
//     const fileInputFiled = document.getElementById("file") as HTMLElement;
//     fileInputFiled.value=null;
//   }

// }
onClickCloseBtn() {
    this.props.onToggleFormModel();
}

  render(){
    let classNames = [];
        if (this.state.isValidated) {
            classNames.push('was-validated');
        }
    return (
      <div className="contactForm">
        {this.state.mailSent &&
                                <div className="success-alert alert">Thank you for contacting us.</div>
                            }
                            {this.state.error &&
                                <div className="alert-danger alert">{this.state.error}</div>
                            }
<form  method="post" action="#" ref={form => this.formEl = form} className={classNames} noValidate>
      <div className="row ">
        <div className="col-md-6">
            <div className="form-group">
              <input className="form-control" placeholder="First Name"  type="text" name="firstName" id="firstName" required={true} value={this.state.firstName} onChange={e => this.setState({ firstName: e.target.value })}/>
              <div className="invalid-feedback"></div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group">
              <input className="form-control" placeholder="Last Name" type="text" name="lastName" id="lastName" required={true} value={this.state.lastName} onChange={e => this.setState({ lastName: e.target.value })}/>
              <div className="invalid-feedback"></div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group">
              <input className="form-control" placeholder="Email"  type="text" name="email" id="email" required={true} value={this.state.email} onChange={e => this.setState({ email: e.target.value })}/>
              <div className="invalid-feedback"></div>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-group">
              <input className="form-control" placeholder="Phone Number"  type="tel" name="phoneNumber" id="phoneNumber" required={true} value={this.state.phoneNumber} onChange={e => this.setState({ phoneNumber: e.target.value })}/>
              <div className="invalid-feedback"></div>
            </div>
        </div>
        <div className="col-md-12">
            <div className="form-group">
              <input className="form-control" placeholder="Subject"  type="text" name="subject" id="subject" required={true} value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })}/>
              <div className="invalid-feedback"></div>
            </div>
        </div>
        {/* <div className="col-md-6">
            <div className="form-group fileUpload">
              <div className="fileUpload">
              <input type="file" accept=".pdf,.doc,.docx" className="form-control" name="file" id="file"
             onChange={e => {this.ValidateFileExt({ file: e.target.files}); this.setState({ file: e.target.files}) } } />
              <div className="invalid-feedback"></div>
              <div className="form-control" id="fileLabel">Send your Resume</div>
              <button className="btn btn-default">
                Upload
              </button>
              </div>
            </div>
        </div> */}
        {/* <div className="col-md-6">
            <div className="form-group">
              <input type="file" className="form-control"  name="file" id="file" required={true} onChange={e => this.setState({ file: e.target.files})}/>
              <div className="invalid-feedback"></div>
            </div>
        </div> */}
        <div className="col-md-12">
            <div className="form-group">
            <textarea className="form-control"  name="message" id="message" required={true} value={this.state.message} onChange={e => this.setState({ message: e.target.value })}></textarea>
            <div className="invalid-feedback"></div>
            </div>
        </div>
        <div className="col-md-12">
            <div className="form-group text-right">
            <button className="btn btn-default" onClick={e => this.handleFormSubmit(e)} type="submit">
              Submit
            </button>
            </div>
        </div>
      </div>
    </form>
      </div>

  );
}

}

export default ContactForm;
